import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormTerms = lazy(
  () => import("./form-terms-c-STzdQw.js").then((module) => ({
    default: module.FormTerms
  }))
);
function FormTerms({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormTerms, { ...props });
}
const useFormTermsContent = () => {
  return useMemo(
    () => ({
      formTerms: {
        component: FormTerms
      }
    }),
    []
  );
};
export {
  LazyFormTerms as FormTerms,
  useFormTermsContent
};
